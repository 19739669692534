import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import Contact from '../components/framework/contact';
import Framework from '../components/framework';
import Link from '../components/framework/link';
import Seo from '../components/framework/seo';
import withLocation from '../middleware/withLocation';

const InformationPage = ({ location }) => {
	const { t } = useTranslation('information');

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description')}
				tags={t('meta_keywords')}
				title={t('title')}
			/>

			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-10">
						<h1 className="h2">{t('t1')}</h1>

						<h2 className="h6 mt-5 my-mt-5">{t('t2')}</h2>
						<Contact
							email="info@testmottagningen.se"
							phoneNumber="+46 8 14 24 49"
						/>

						<Link to="/print">
							<div style={{ height: '100px', width: '100px' }}></div>
						</Link>
					</div>
				</div>
			</div>
		</Framework>
	);
};

InformationPage.propTypes = {
	location: PropTypes.object.isRequired,
};

export default withLocation(InformationPage);

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					data
					language
					ns
				}
			}
		}
	}
`;
