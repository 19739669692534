import PropTypes from 'prop-types';
import React from 'react';

import { TelephoneFill } from 'react-bootstrap-icons';
import { EnvelopeFill } from 'react-bootstrap-icons';

const Contact = ({ email, phoneNumber }) => {
	return (
		<>
			<section>
				<div className="row mb-1">
					<div className="col-auto pe-0">
						<div className="title-small text-primary container-icon text-center">
							<TelephoneFill />
						</div>
					</div>
					<div className="col">
						<span className="mb-0">
							<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
						</span>
					</div>
				</div>
				<div className="row">
					<div className="col-auto pe-0">
						<div className="title-small text-primary container-icon text-center">
							<EnvelopeFill />
						</div>
					</div>
					<div className="col">
						<span className="mb-0">
							<a href={`mailto:${email}`}>{email}</a>
						</span>
					</div>
				</div>
			</section>
		</>
	);
};

Contact.propTypes = {
	email: PropTypes.string,
	location: PropTypes.string,
	phoneNumber: PropTypes.string,
};

export default Contact;
